import styled from 'styled-components';
import { breakpoint } from 'theme';

import Typography from 'components/atoms/typography';

import DotsGrid from '../../molecules/dots-grid';

const minusMarginTop = '-128px';
const minusMarginTopMobile = '-116px';
const paddingTopWrapper = '200px';
const paddingTopWrapperMobile = '120px';

export const StyledWrapper = styled.div`
  margin-top: ${minusMarginTopMobile};
  padding: ${({ theme }) => theme.gap.sm};
  padding-top: ${paddingTopWrapperMobile};
  background-color: ${({ theme }) => theme.colors.primary50};

  ${breakpoint.lg} {
    overflow: visible;
    margin-top: ${minusMarginTop};
    padding-top: ${paddingTopWrapper};

    margin-bottom: ${({ theme }) => theme.gap.xl};
  }
`;

export const StyledContainer = styled.div`
  margin: auto;
  max-width: ${({ theme }) => theme.wrapper};
  position: relative;
`;

export const StyledTextWrapper = styled.div`
  margin: auto;
  max-width: ${({ theme }) => theme.wrapperXs};
`;

export const StyledDescription = styled(Typography)`
  margin-top: ${({ theme }) => theme.gap.sm};

  ${breakpoint.lg} {
    margin: auto;
    margin-top: ${({ theme }) => theme.gap.md};
    max-width: ${({ theme }) => theme.wrapperXs};
  }
`;
export const StyledDotsGrid = styled(DotsGrid)`
  display: none;
  z-index: 10;
  top: 46%;

  ${breakpoint.lg} {
    display: block;
    right: 0px;
    top: 65%;
  }

  ${breakpoint.xl} {
    right: -155px;
  }
`;
