import React from 'react';

import OpinionSection from 'components/molecules/opinion-section/opinion-section';
import { ButtonType } from 'components/atoms/button';
import Typography from 'components/atoms/typography';
import { WpImageType } from 'components/atoms/wp-image';

import { useScreenService } from '../../../hooks/use-screen-service';

import {
  StyledContainer,
  StyledDescription,
  StyledDotsGrid,
  StyledTextWrapper,
  StyledWrapper,
} from './head-extends-team.styles';

export type HeadExtendsTeamType = {
  heading: string;
  text: string;
  meetTeam: {
    cite: string;
    fullName: string;
    position: string;
    linkButton: ButtonType;
    image: WpImageType;
  };
};

const HeadExtendsTeam = ({ heading, text, meetTeam }: HeadExtendsTeamType) => {
  const { isMobile } = useScreenService();

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledTextWrapper>
          <Typography color="white" variant="title1" html={heading} />
          <StyledDescription color="white" variant="body1" html={text} />
        </StyledTextWrapper>
        <OpinionSection photoAbsolute {...meetTeam} mobilePadding="0" />
        <StyledDotsGrid
          width={isMobile ? 0 : 3}
          height={isMobile ? 0 : 6}
          color={isMobile ? 'complementary2' : 'complementary2'}
        />
      </StyledContainer>
    </StyledWrapper>
  );
};

export default HeadExtendsTeam;
